import React from 'react';

import './Spinner.css';

const Spinner = () => (
    <div className="spinner large">
        <div className="spinner-wrapper">
            <div className="rotator">
                <div className="inner-spin"></div>
                <div className="inner-spin"></div>
            </div>
        </div>
    </div>
);

export default Spinner;