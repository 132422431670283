import React, { Component } from 'react';
import { withAuth } from '@okta/okta-react';
import { Redirect } from 'react-router-dom'

export default (withAuth(class Login extends Component {
    

    constructor(props) {
        super(props);
        this.state = { redirect: false };
        this.checkAuthentication = this.checkAuthentication.bind(this);
    }

    async checkAuthentication() {

        const authenticated = await this.props.auth.isAuthenticated();
        if (!authenticated) {
            this.props.auth.login('/home');
        }
        else{
            this.setState({
                redirect: true
              })
        }
    }


    async loginViaOkta() {
        console.log("login Home")
    }

    async logout() {
        // Redirect to '/' after logout
        this.props.auth.logout('/');
    }

    renderRedirect = () => {
        if (this.state.redirect) {
          return <Redirect to='/home' />
        }
        else {
            return 'Logging In';
        }
      }

    componentDidMount = () => {
        this.checkAuthentication();
    }
    render() {
        return (
            <div> 
                {this.renderRedirect()}
                
            </div>
        )
    }
}));
