import React from 'react';
import Popper from '@material-ui/core/Popper';
import IconButton from '@material-ui/core/IconButton';
import AccountCircleIcon from '@material-ui/icons/AccountCircleSharp';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EmailIcon from '@material-ui/icons/Email';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';


class UserAccountPopper extends React.Component {
  state = {
    anchorEl: null,
    open: false,
    placement: null,
  };

  handleClick = placement => event => {
    const { currentTarget } = event;
    this.setState(state => ({
      anchorEl: currentTarget,
      open: state.placement !== placement || !state.open,
      placement,
    }));
  };

  render() {
    const { anchorEl, open, placement } = this.state;

    return (
      <div>
        <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
            <Paper>
                <List component="nav">
                    <ListItem button>
                        <ListItemIcon>
                            <AccessibilityNewIcon />
                        </ListItemIcon>
                        <ListItemText primary={this.props.name} />
                    </ListItem>

                    <ListItem button>
                        <ListItemIcon>
                            <EmailIcon />
                        </ListItemIcon>
                        <ListItemText primary={this.props.email} />
                    </ListItem>
                   
                </List>
            </Paper>
            </Fade>
          )}
        </Popper>
        <IconButton onClick={this.handleClick('bottom')} color="inherit">
                <AccountCircleIcon />
        </IconButton>
      </div>
    );
  }
}


export default (UserAccountPopper);