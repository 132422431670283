//import axios from "axios";
//import { adalApiFetch } from "../../adalConfig";
import {GET_USER_DETAILS} from './actionTypes';
import loadData from "../../util/loadData";
import { ENVIRONMENT } from '../../environment-config';

var OktaAuth = require('@okta/okta-auth-js');
const jsonData = loadData();
var oktaIssuer='';
var oktaClientID ='';
switch(ENVIRONMENT){
    case "prod":
    oktaIssuer = jsonData.api.oktaIssuerProd;
    oktaClientID = jsonData.api.oktaClientIDProd;
    break;
    case "test":
    oktaIssuer = jsonData.api.oktaIssuerPreProd;
    oktaClientID = jsonData.api.oktaClientIDPreProd;
    break;
    case "dev":
    oktaIssuer = jsonData.api.oktaIssuerDev;
    oktaClientID = jsonData.api.oktaClientIDDev;
    break;
    case "lab":
    oktaIssuer = jsonData.api.oktaIssuerLab;
    oktaClientID = jsonData.api.oktaClientIDLab;
    break;
    default:
    oktaIssuer = jsonData.api.oktaIssuerLab;
    oktaClientID = jsonData.api.oktaClientIDLab;
    break;
}

export const receiveUserDetails = (user, accessToken, idToken) => ({ type: GET_USER_DETAILS, user, accessToken, idToken });


export const saveOktaData = (user, accessToken, idToken ) => async (dispatch) => {
    dispatch(receiveUserDetails(user, accessToken, idToken));
};

export const onLogout = () => () => {
    var config = {
        issuer: oktaIssuer,
        clientId: oktaClientID,
      };
    var authClient = new OktaAuth(config);
    authClient.signOut()
                .then(function() {
                    authClient.tokenManager.clear()
                    window.location.replace("/")
                })
                .fail(function(err) {
                    if(err.errorCode !== "E0000007") { 
                        console.log(err) 
                        throw err;
                      } 
                    else{
                        authClient.tokenManager.clear()
                        window.location.replace("/")
                    }
                }); 

}
export default onLogout;