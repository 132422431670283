import React from 'react';
import { connect } from 'react-redux';
import {onTableChange, onTableAdd, onTableDelete} from '../../redux/actions/home';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import TextField from '@material-ui/core/TextField';

const mapStateToProps = ({home}) => {
    return {
      shipments: home.shipments
    }
  }


export default connect(mapStateToProps, {onTableChange, onTableAdd, onTableDelete})( function Table({shipments, onTableChange, onTableAdd, onTableDelete}) {
  const columns = [
    { title: 'SKU Number', field: 'material', editComponent: props => {
      return (
        <TextField
        placeholder="SKU Number"
        fullWidth
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
      />
      )
    } },
    { title: 'Lot Number', field: 'lot' , editComponent: props => {
      return (
        <TextField
        placeholder="Lot Number"
        fullWidth
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
      />
      )
    }},
    { title: 'Pack Date', field: 'packDate', editComponent: props => {
      return (
        <TextField
        type="date"
        fullWidth
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
      />
      )
    }
  },
  { title: 'Expiration Date', field: 'expireDate', editComponent: props => {
    return (
      <TextField
      type="date"
      fullWidth
      value={props.value}
      onChange={e => props.onChange(e.target.value)}
      InputLabelProps={{
        shrink: true,
      }}
    />
    )
  }
},
    { title: 'Cases', field: 'cases', editComponent: props => {
      return (
        <TextField
        type="number"
        fullWidth
        placeholder="Cases"
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
      />
      )
    }},
    { title: 'Total SKU Weight', field: 'totalLbs' , editComponent: props => {
      return (
        <TextField
        type="number"
        fullWidth
        placeholder="Total SKU Weight"
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
      />
      )
    }}
  ];
    const tableIcons = {
        Add: AddBox,
        Check: Check,
        Clear: Clear,
        Delete: DeleteOutline,
        DetailPanel: ChevronRight,
        Edit: Edit,
        Export: SaveAlt,
        Filter: FilterList,
        FirstPage: FirstPage,
        LastPage: LastPage,
        NextPage: ChevronRight,
        PreviousPage: ChevronLeft,
        ResetSearch: Clear,
        Search: Search,
        SortArrow: ArrowUpward,
        ThirdStateCheck: Remove,
        ViewColumn: ViewColumn
        };


  return (
    <MaterialTable
      style={{backgroundColor:'#EDF3F9', color: 'inherit'}}
      title="Shipment Details"
      columns={columns}
      data={shipments}
      icons={tableIcons}

      editable={{
        onRowAdd: newData =>
          new Promise(resolve => {
            setTimeout(() => {
                    resolve();
                    onTableAdd(newData);
            }, 600);
          }),
        onRowUpdate: (newData, oldData) => 
            new Promise(resolve => {
                setTimeout(() => {
                    resolve();
                    onTableChange(oldData, newData);
                }, 600);
            }),
        onRowDelete: oldData =>
          new Promise(resolve => {
            setTimeout(() => {
                    resolve();
                    onTableDelete(oldData)
            }, 600);
          }),
      }}
    />
  );
});