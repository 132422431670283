import {ON_HEADER_CHANGE, ON_TABLE_MODIFIED, ON_SUBMIT_CLEAR, ON_SUBMIT, ON_SUBMIT_ERROR} from '../actions/actionTypes';

const initialState = {
poNum : '',
shipDate: '',
alert: null,
submitted: false,
shipments: []
};

export default (state = initialState, action) => {
switch(action.type) {
case ON_HEADER_CHANGE:
  switch(action.id){
      case "poNum":
            return {
                ...state,
                poNum : action.value,
                alert: null
            }
      case "shipDate": 
            return {
                ...state,
                shipDate : action.value,
                alert: null
            }
      default:
          return {...state};
  }
case ON_TABLE_MODIFIED:
    return {
        ...state,
        shipments : action.shipments,
        alert: null
    }
case  ON_SUBMIT_CLEAR:
    return{
        ...state,
        poNum : '',
        shipDate: '',
        destination: '',
        shipments: [],
        submitted: false,
        alert: 'Submitted to Tyson SAP System for Processing...'
    }
case ON_SUBMIT:
    return {
        ...state,
        submitted : true
    }
case ON_SUBMIT_ERROR:
    return {
        ...state,
        submitted : false
    }
default:
  return state;
}
};