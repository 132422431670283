let env;

const hostname = window && window.location && window.location.hostname;

switch(hostname){
    case "localhost":
        env = "local";
        break;
    case "inbound-delivery-lab.tyson.com":
        env = "lab";
        break;
    case "inbound-delivery-dev.tyson.com":
        env = "dev";
        break;
    case "inbound-delivery-preprod.tyson.com":
        env = "test";
        break;
    case "inbound-delivery.tyson.com":
        env = "prod";
        break;
    default :
        env = "local";
        break;
}

export const ENVIRONMENT = `${env}`;