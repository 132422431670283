import {GET_USER_DETAILS} from '../actions/actionTypes';

const initialState = {
user : '',
accessToken: '',
idToken: ''
};

export default (state = initialState, action) => {
switch(action.type) {
case GET_USER_DETAILS:
  return {
    ...state,
    user: action.user,
    accessToken: action.accessToken,
    idToken: action.idToken
  };
default:
  return state;
}
};