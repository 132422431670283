import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import './index.css';
import Header from './features/Header/Header';
import Home from './features/Home/Home';
import Login from './features/Login/Login'
import configureStore from './configureStore';
import * as serviceWorker from './serviceWorker';
import axios from "axios";
import {MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { ENVIRONMENT } from './environment-config';
import loadData from './util/loadData';
import { Security, ImplicitCallback } from '@okta/okta-react';

const jsonData = loadData();

var okta_client_id;
var okta_issuer;

switch(ENVIRONMENT){
  case "prod":
  axios.defaults.baseURL = jsonData.api.baseURLProd;
  okta_client_id = jsonData.api.oktaClientIDProd;
  okta_issuer = jsonData.api.oktaIssuerProd;
  break;
  case "test":
  axios.defaults.baseURL = jsonData.api.baseURLTest;
  okta_client_id = jsonData.api.oktaClientIDPreProd;
  okta_issuer = jsonData.api.oktaIssuerPreProd;
  break;
  case "dev":
  axios.defaults.baseURL = jsonData.api.baseURLDev;
  okta_client_id = jsonData.api.oktaClientIDDev;
  okta_issuer = jsonData.api.oktaIssuerDev;
  break;
  case "lab":
  axios.defaults.baseURL = jsonData.api.baseURLLab;
  okta_client_id = jsonData.api.oktaClientIDLab;
  okta_issuer = jsonData.api.oktaIssuerLab;
  break;
  default:
  axios.defaults.baseURL = jsonData.api.baseURLLab;
  okta_client_id = jsonData.api.oktaClientIDLab;
  okta_issuer = jsonData.api.oktaIssuerLab;
  break;
}

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    color: '#FFF',
    fontFamily: [
      '"Segoe UI"',
    ].join(','),
  },
});

const config = {
  issuer: okta_issuer,
  redirect_uri: window.location.origin + '/implicit/callback',
  client_id: okta_client_id,
  scope:['openid' ,'profile','idelapi:readwrite']
}

const DefaultLayout = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={matchProps => (
      <div>
        <Header {...matchProps}>
          <Component {...matchProps} />
        </Header>
      </div>
    )}
  />
);

const rootElement = <Provider store={configureStore()}>
  <MuiThemeProvider theme={theme}>
    <Router>
        <Security 
          issuer={config.issuer} 
          client_id={config.client_id} 
          redirect_uri={config.redirect_uri}
          scope={config.scope}
        >
          <Switch>
              <Route path='/' exact={true} component={Login}/>
              <DefaultLayout path='/home' exact={true} component={Home}/>
              <Route path='/implicit/callback' component={ImplicitCallback}/>
              {/* <DefaultLayout exact path="/mentor" component={BeAMentor}/>
              <DefaultLayout exact path="/knowyou" component={GetToKnowYou}/>
              <DefaultLayout exact path="/shouts" component={ShoutOuts}/>
              <DefaultLayout exact path="/speak" component={SpeakUp}/> */}
          </Switch>
        </Security>
  </Router>   
  </MuiThemeProvider> 
</Provider>;

//axios.defaults.headers.common["Authorization"] = "BEARER " + getToken();

axios.interceptors.request.use(req => {
	console.log(req);
	return req;
});

axios.interceptors.response.use(res => {
	console.log(res);
	return res;
});

ReactDOM.render(rootElement, document.getElementById('root'));

serviceWorker.unregister();
